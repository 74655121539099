export const reportedDevicesMap = {
  Reported: 'Reported last 5 days',
  NotReported: 'Not Reported',
};

export const DeviceStatus = {
  unknown: 'unknown',
  enforced: 'enforced',
  audit: 'audit',
};

export const healthStatus = {
  reported: 'reported',
  notReported: 'notReported',
};

export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
