import { useCallback, useEffect, useRef, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import useInfiniteFetch from '../../hooks/useInfiniteFetch';
import { EXPANDED_ROWS_LIMIT } from '../../constants/table';
import useDebounce from '../../hooks/auth/useDebounce';
import { colors } from '../../theme/palette/darkPalette';

const StyledTextField = styled(TextField)({
  '& svg': {
    fill: '#fff',
  },
  '& label.Mui-focused': {
    color: colors.green,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.green,
    },
  },
});

const FilterAutocomplete = ({
  selectedValue,
  onChangeFilter,
  fieldIndex,
  sectionIndex,
  queryKey,
  fetchFunction,
  expandedRowsLimit = EXPANDED_ROWS_LIMIT,
  itemName,
  source,
}) => {
  const [searchValue, setSearchValue] = useState(selectedValue);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const isInitialSelectedValueSet = useRef(false);

  const { data, lastElementRef, isLoading } = useInfiniteFetch({
    queryKey,
    fetchFunction,
    expandedRowsLimit,
    itemName,
    searchValue: debouncedSearchValue,
  });

  useEffect(() => {
    if (selectedValue && !searchValue && !isInitialSelectedValueSet.current) {
      setSearchValue(selectedValue);
      isInitialSelectedValueSet.current = true;
    }
  }, [itemName, searchValue, selectedValue]);

  const onChangeSearchValue = useCallback(
    event => {
      setSearchValue(event.target.value);

      if (!event.target.value) {
        onChangeFilter({ source, fieldIndex, sectionIndex, fieldValue: '' });
      }
    },
    [setSearchValue, onChangeFilter, fieldIndex, sectionIndex, source],
  );

  const formattedData = data.map(item => ({
    value: item?.name || item,
    label: item?.name || item,
  }));

  return (
    <Autocomplete
      disableClearable
      value={debouncedSearchValue}
      onChange={(event, data) => {
        onChangeFilter({
          source,
          fieldIndex,
          sectionIndex,
          fieldValue: data?.value || '',
        });
      }}
      isOptionEqualToValue={(option, selectedOption) => option?.value === selectedOption?.value}
      options={formattedData}
      loading={isLoading}
      renderInput={params => (
        <StyledTextField
          {...params}
          value={searchValue}
          onChange={onChangeSearchValue}
          InputLabelProps={{
            sx: {
              color: '#fff',
            },
          }}
        />
      )}
      ref={lastElementRef}
    />
  );
};

export default FilterAutocomplete;
