export const pagesText = {
  register: {
    title: 'Get started, register below',
    subtitle: 'Enter details for account information',
    question: 'Already have an account?',
    questionLinkText: 'Login',
    registerButton: 'Register',
    termsAndConditions: {
      agree: 'By registering, I agree to App Control',
      termsOfService: 'Terms of Service',
      and: 'and',
      privacyPolicy: 'Privacy Policy',
    },
  },
  login: {
    acceptInvitationTitle: 'To accept the invitation logon to your account',
    title: 'Sign In',
    question: 'Forgot password?',
    getStarted: {
      question: 'Don’t have an account?',
      linkText: 'Get started',
    },
    loginButton: 'Login',
  },
  verifyEmail: {
    title: 'Verify Your Email to Login',
    subtitle:
      'To finish registration, please verify your account by clicking the link sent to your email.',
    additionalInfo: 'Check your email and follow the instructions',
    resendButton: 'Resend verification email',
    loginButton: 'Back to login',
  },
  resetPassword: {
    title: 'Reset Password',
    resetButton: 'Reset Password',
  },
  dashboard: {
    title: 'All Apps',
    name: 'Apps',
    breadcrumbs: {
      pageName: 'App Catalog',
    },
    headings: {
      softwareCenterName: 'software center name',
      publisher: 'Publisher',
      versionsCount: 'Total Versions',
      latestVersion: 'Latest Version',
      totalInstalls: 'Total Installs',
      installSize: 'Install Size',
      configManagerName: 'Config Manager Name',
    },
  },
  breadcrumbTitle: 'App Control',
  appsDetails: {
    headings: {
      applicationName: 'Application Name',
      blockedByDynamicCodeSecurity: 'Dynamic Code Security',
      trustedByISG: 'Trusted by ISG',
      totalFiles: 'Total files',
    },
    generalSection: {
      title: 'General',
    },
  },
  appsStarterPopup: {
    title: 'Welcome to App Control',
    description:
      'To get started with App Control it needs to collect some details from your Microsoft Endpoint Configuration Manager. Please download the binary below to setup the connection between your environment and App Control.',
    extraDescription:
      'For more details around the process and the prerequisites that need to be in place, please visit our Documentation page',
  },
  forgotPassword: {
    title: 'Forgot your password?',
    subtitle: 'Enter your email address below and we’ll send you a reset link',
    submitButton: 'Send Reset Email',
    backLogin: 'Back to login',
  },
  userProfileMenu: {
    settings: 'Settings',
    changeOrganization: 'Change environment',
    logout: 'Logout',
  },
  tableExpandedRowActions: {
    info: 'Information',
    security: 'Security',
    license: 'License',
    actions: 'Actions',
  },
  securityExpandedRowActions: {
    securityCatalog: 'Security Catalog',
    securityPolicyWithFallback: `WDAC Policy (with FallBack)`,
    securityPolicyWithoutFallback: `WDAC Policy (without FallBack)`,
    all: 'All',
  },
  tableAllVersionsButton: 'See All Versions',
  environments: {
    validationMessages: {
      intune: {
        required: 'Azure Tenant ID is required',
        length: 'Azure Tenant ID must be 36 characters',
        guid: 'Azure Tenant ID must be a valid GUID',
      },
      other: {
        required: 'Environment id is required',
        length: 'Custom id must be 36 characters',
        guid: 'Custom ID must be a valid GUID',
      },
    },
    applicationManagementLabel: 'Application Management solutions',
    applicationManagementPlaceholder: 'Select Application Management solution',
    title: 'Environments',
    addButton: 'Add New Environment',
    editButton: 'Edit',
    supportIdInfoText: `You can copy/paste your Support ID from the About Configuration Manager dialog box. You can open this dialog box from any Configuration Manager Admin UI by opening the menu on the top left of the UI.`,
    intuneInfoText: 'Where can I find my Azure Tenant ID ?',
    intuneInfoLinkText: 'How to find your tenant ID - Microsoft Entra | Microsoft Learn',
    environmentsTable: {
      headings: {
        environmentName: 'Environment Name',
        description: 'Description',
        actions: 'Actions',
      },
      action: {
        select: 'Select',
        selected: 'Selected',
        edit: 'Edit',
        delete: 'Delete',
      },
    },
    formDialog: {
      addTitle: 'Add New Environment',
      editTitle: 'Edit Environment',
    },
    deviceLoggingApiKey: {
      regenerateSuccess: 'Device Logging API Key has been regenerated successfully',
    },
    envIdMenu: {
      configurationManager: 'Configuration manager',
      intune: 'Intune',
      other: 'Other management solution',
    },
  },
  authLabels: {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    password: 'Password',
    repeatPassword: 'Confirm password',
    companyName: 'Company name',
  },
  userProfileEditMessage: 'Your profile has been updated successfully',
  noEnvironmentAlert: {
    title: 'No Linked Environment and/or no selected environments',
    descriptionFirstPart: `Oops! It seems like there are no environments and/or no selected environments currently associated with your account. To get
      started, please create a new environment using a valid support ID and take the next step to
      configure your server. If you need any assistance, our support team is here to help! Open this`,
    descriptionSecondPart: 'to create a new environment',
    descriptionLinkText: 'link',
  },
  passwordValidationFields: {
    min: 'At least 6 characters long.',
    uppercase: 'At least one uppercase letter.',
    lowercase: 'At least one lowercase letter.',
    numeric: 'At least one numeric character.',
    nonAlphanumeric: 'At least one non-alphanumeric character.',
  },
  deleteEnvironmentButton: 'Delete Environment',
  deleteEnvironment: {
    title: 'Are You Sure You Want to Delete This Environment?',
    description: `Application Catalog has been created. Deleting the environment cannot be undone and your
        application catalog data will be deleted.`,
    cancelButton: 'Go Back',
    confirmButton: 'Yes, Delete',
  },
  switchEnvironmentDialog: {
    descriptionPartOne: 'You are about to switch to Environment',
    descriptionPartTwo: 'Would you like to continue?',
    cancelButton: 'Go Back',
    confirmButton: 'Yes',
  },
  filters: {
    sorting: {
      title: 'Sort',
      ascending: 'Ascending',
      descending: 'Descending',
    },
    filter: {
      title: 'Filter',
      contain: 'Contains',
      notContain: 'Does Not Contain',
      executed: 'Execution',
      installed: 'Installation',
      yes: 'Yes',
      no: 'No',
    },
    addConditionButton: 'Add Condition',
    applyFiltersButton: 'Apply Filter',
    saveFiltersButton: 'Show results',
    addFilterButton: 'Add',
    removeFilterButton: 'Remove',
    resetFiltersButton: 'Reset',
    totalNumber: 'Total Number',
    blockedByDynamicCodeSecurity: 'Blocked by dynamic code security',
    trustedByISG: 'Trusted by The Intelligent Security Graph',
  },
  totalNumberVersions: 'Total number of app versions',
  cancelButton: 'Cancel',
  sidebarNavigation: {
    apps: 'Apps',
    license: 'License',
    actions: 'Actions',
    devices: 'Devices',
    dashboard: 'Dashboard',
  },
  downloadsNavigation: {
    serverAgent: 'Download Server Agent',
    clientAgent: 'Download Client Agent',
  },
  documentation: {
    checkDocs: 'Check our docs',
    faq: 'Check our FAQ',
  },
  error: {
    title: 'Page Not Found',
    description:
      "The page you requested may have been moved, deleted, or you may have the wrong URL. Let's get you back on track.",
    button: 'Back to Home',
    errorText: '404',
  },
  passwordHintTitle: 'Password must have',
  noSearchResult: 'No results found',
  notifications: {
    startDownload: 'File is getting prepared in the background.',
    readyToDownload: 'File is ready to download',
    downloadFailed: 'File download failed',
    markAllAsRead: 'The notifications have been successfully marked as read.',
  },
  bulkDownloadPage: {
    bulkDownload: 'Bulk Download',
    bulkDownloadOptionsButton: 'Create New Bulk Download',
    breadcrumbs: {
      pageName: 'Bulk Download',
    },
    noResult: {
      title: 'No Bulk Files Found',
      description: `Oops! It looks like there are no bulk files currently available. Don't worry, you can easily download bulk files by selecting the corresponding download option. Once downloaded, you'll find the status and history of your bulk files in the Bulk File History section. Get started now to manage your data more efficiently!`,
    },
    securityDownloadDialog: {
      title: 'Choose file type for bulk download',
    },
  },
  companyDetails: {
    placeholder: 'Enter Company Name',
    editCompanySnackbarText: 'Customer name updated successfully',
    createCompanySnackbarText: 'Company created successfully',
    update: {
      title: 'Edit company details',
    },
    create: {
      title: 'Create company',
    },
  },
  companyUsers: {
    title: 'Company Users',
    headings: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      status: 'Status',
    },
  },
  inviteUsers: {
    title: 'Invite Users',
    headings: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      status: 'Status',
    },
    validationMessages: {
      firstName: 'First name is required',
      lastName: 'Last name is required',
      email: 'Email is required',
    },
    inputFields: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
    },
    resendInvitesButton: 'Resend Invites',
    invitesButton: 'Invite User',
    addCustomerSnackbar: 'Invite has been sent successfully',
  },
  downloadButton: 'Download',
  noVersionFound: 'No version found',
  noDataFound: 'No data found',
  somethingWentWrong: 'Oops something went wrong, please try again later',
  yesCell: 'Yes',
  noCell: 'No',
  companyList: {
    title: 'Companies',
    searchPlaceholder: 'Enter company name',
    selectCompany: 'View company',
    createCompany: 'Create a new Company',
    noResult: 'No companies found',
    headings: {
      companyName: 'Company Name',
      createdDate: 'Created Date',
      updatedAt: 'Updated Date',
      action: 'Action',
    },
    noCompanyAlert: {
      title: 'No Associated Company',
      description: 'Oops! Your account is not associated to any company currently.',
    },
  },
  setupAccount: {
    title: 'To accept the invitation setup your user account',
    button: 'Accept Invitation',
  },
  failedToAcceptInvitation:
    'The invite is for another user with a different email address - to accept the invite logon with the correct email address',
  emailInvitationPage: {
    title: {
      setupAccount: 'To accept the invitation setup your user account',
      acceptInvitation: 'To accept the invitation logon to your account',
    },
    buttonText: {
      setupAccount: 'Setup Account',
      acceptInvitation: 'Login',
    },
  },
  emailValidationErrorMessage: 'Email must be a valid email address',
  selectCompany: 'Select company',
  emailValidationPopup: {
    title: 'Oops',
    description: `The link to your invitation has expired or is not valid. Please contact your referrer
            for a re-invite`,
  },
  deviceApiKeyDialog: {
    title: 'Device Logging API Key',
    helpText: 'To use our device logging agent see the ',
    closeButton: 'Close',
    regenerateButton: 'Regenerate',
    generateButton: 'Generate',
    copy: 'Copy',
    regenerateTextMessage: `You can regenerate the key, however if you do ALL existing agents 
using this key will stop functioning until you update them with a new key`,
    noKeyFound: "No API key Present. Click 'Generate' to create one",
  },
  confirmDeviceKeyRegeneration: {
    title: 'Are you sure you want to regenerate the Device Logging API Key?',
    description: `If you regenerate the key, all existing agents using this key will stop functioning until you update them with a new key`,
    confirmActionMessage: 'Type "I CONFIRM" to confirm the action',
    cancelButton: 'Cancel',
    confirmButton: 'Yes, Regenerate',
  },
  devices: {
    title: 'Devices',
    headings: {
      name: 'Device',
      tagName: 'Tag',
      lastContactDate: 'Last Contact Date',
      status: 'Status',
      wdacStatus: 'WDAC Status',
      osVersion: 'OS Version',
      osSku: 'OS SKU',
      osType: 'OS Type',
      logCollectorVersion: 'Log collector version',
    },
    filters: {
      lastContactDate: {
        greaterThan: 'Last Contact Date is greater than',
        lessThan: 'Last Contact Date is less than',
      },
      status: {
        approved: 'Approved',
        unknown: 'Unknown',
        revoked: 'Revoked',
      },
      wdacDeviceStatus: {
        unknown: 'Unknown',
        enforced: 'Enforced',
        audit: 'Audit',
      },
      healthStatus: {
        reported: 'Reported',
        notReported: 'Not Reported',
      },
    },
    editTagModal: {
      title: 'Edit Tag',
      onCloseButton: 'Close',
      removeTagMessage: 'Tag removed successfully',
    },
    successFullyUpdateDeviceStatus: 'Device status has been updated successfully',
    successFullyUpdateDeviceTag: 'Device tag has been updated successfully',
    newTagPlaceholder: 'Click Add button to add new tag',
    totalDevices: 'Total Devices',
  },
  execution: 'Execution',
  installation: 'Installation',
  appDetails: {
    detailsSection: {
      publishers: 'Certificate publishers',
      unSignedFiles: 'Unsigned files',
      outsidePath: "Files outside 'program files/progam files(x86)' folders",
      writableFolder: 'User writable folders',
      publisherName: 'Publisher Name',
      validFrom: 'Valid From',
      validTo: 'Valid To',
      tbsHash: 'TBS Hash',
      folderName: 'Folder Name',
      fileName: 'File Name',
      filePath: 'File Path',
      path: 'Path',
    },
    generalSection: {
      environmentApplicationName: 'Application Name',
      installBlockedByDynamicCodeSecurity:
        'Is application installation blocked by Dynamic Code Security',
      executionBlockedByDynamicCodeSecurity:
        'Is application execution blocked by Dynamic Code Security',
      executionTrustedByISG: 'Is application execution trusted by the ISG',
      executionTrustedByISGDate: 'ISG execution validated on',
      installTrustedByISG: 'Is application installation trusted by the ISG',
      installTrustedByISGDate: 'ISG installation validated on',
      filesCount: 'Number of files in this application',
    },
  },
  deviceDashboard: {
    totalDeviceInEnvironment: 'Total Devices in Environment',
    devicesOnline: 'Devices online in last 5 days',
  },
  deleteTagDialog: {
    title: 'Are you sure you want to delete the tag ?',
    yes: 'Yes',
    no: 'No',
    description: 'The tag will be deleted from the  tag list as well as removed from',
  },
  copyTextMessage: 'Copied to clipboard',
};
