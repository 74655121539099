import { memo, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import FiltersDropdown from './FiltersDropdown';
import MenuItem from '../MenuItem';
import { pagesText } from '../../constants/pagesText';
import FilterField from './FilterField';
import FilterSectionTitle from './FilterSectionTitle';
import { colors } from '../../theme/palette/darkPalette';
import Button from '../Button';
import Iconify from '../Iconify';
import DeleteButton from './DeleteButton';
import { filtersConfigs } from './constants';
import FilterDatePicker from './FilterDatePicker';
import { decrementedDayIso } from '../../utils/formatTime';
import FilterAutocomplete from './FilterAutocomplete';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  lineHeight: '30px',
  fontSize: theme.typography.pxToRem(12),
  color: '#fff',
  textTransform: 'uppercase',
}));

const shouldDisableOption = ({ option, filters, sectionIndex }) => {
  const newFilters = [...filters];
  newFilters.splice(sectionIndex, 1);
  if (newFilters.length === 0) return false;
  const firstOption = newFilters[0][0]?.value;

  return firstOption === option.value;
};

const ActiveFilterSection = ({
  title,
  source,
  filters,
  onChangeFilterValue,
  onAddFilter,
  onRemoveFilter,
  onBackClick,
  fetchFunctionsMap,
}) => {
  const getFilterDate = useCallback((value, label) => {
    if (!value) return '';

    if (label === 'To') {
      return decrementedDayIso(value);
    }

    return new Date(value);
  }, []);

  const filterMaps = useMemo(
    () => ({
      menu: ({ filter, sectionIndex, filterIndex, filters }) => (
        <FiltersDropdown
          sectionIndex={sectionIndex}
          fieldIndex={filterIndex}
          label="Condition"
          selectedValue={filter.value}
          source={source}
          onChangeFilter={onChangeFilterValue}>
          {filter.options.map(option => (
            <MenuItem
              disabled={shouldDisableOption({
                option,
                filters,
                sectionIndex,
              })}
              key={option.value}
              value={option.value}
              styles={{
                ':hover': {
                  backgroundColor: colors.bars,
                },
              }}>
              {option.label}
            </MenuItem>
          ))}
        </FiltersDropdown>
      ),
      input: ({ filter, sectionIndex, filterIndex }) => (
        <FilterField
          sectionIndex={sectionIndex}
          fieldIndex={filterIndex}
          label="Value"
          source={source}
          selectedValue={filter.value}
          onChangeFilter={onChangeFilterValue}
        />
      ),
      date: ({ filter, filterIndex, sectionIndex }) => (
        <FilterDatePicker
          source={source}
          selectedValue={getFilterDate(filter.value, filter.label)}
          onChangeFilter={params =>
            onChangeFilterValue({
              ...params,
              label: filter.label,
            })
          }
          fieldIndex={filterIndex}
          sectionIndex={sectionIndex}
          label={filter.label}
        />
      ),
      autocomplete: ({ filter, filterIndex, sectionIndex }) => {
        return (
          <FilterAutocomplete
            selectedValue={filter.value}
            onChangeFilter={onChangeFilterValue}
            fieldIndex={filterIndex}
            sectionIndex={sectionIndex}
            queryKey={filter.queryKey}
            fetchFunction={fetchFunctionsMap[filter.name]}
            itemName={filter.itemName}
            source={source}
          />
        );
      },
    }),
    [onChangeFilterValue, source, getFilterDate, fetchFunctionsMap],
  );

  return (
    <Box padding="26px 0">
      <Stack
        direction="row"
        justifyContent="left"
        alignItems="center"
        gap={1}
        sx={{
          marginBottom: 2.5,
        }}>
        <Iconify
          onClick={onBackClick}
          icon="fe:arrow-left"
          color="#fff"
          width={16}
          height={16}
          sx={{
            cursor: 'pointer',
          }}
        />
        <StyledTitle>{title}</StyledTitle>
      </Stack>
      <FilterSectionTitle>{pagesText.filters.filter.title}</FilterSectionTitle>
      {filters.map((singleFilter, sectionIndex) => (
        <Stack gap={2} key={sectionIndex}>
          {singleFilter.map((filter, filterIndex) =>
            filterMaps[filter.type]({
              filters,
              filter,
              sectionIndex,
              filterIndex,
            }),
          )}
          <Stack direction="row" justifyContent="space-between">
            {filters.length - 1 === sectionIndex &&
              filters.length !== filtersConfigs[source].filtersCount && (
                <Button
                  onClick={onAddFilter}
                  variant="text"
                  startIcon={
                    <Iconify icon="ic:round-plus" color={colors.steelBlue} width={16} height={16} />
                  }
                  styles={{
                    color: colors.steelBlue,
                    maxWidth: '34px',
                  }}>
                  {pagesText.filters.addFilterButton}
                </Button>
              )}
            {filters.length !== 1 && filtersConfigs[source].canAddFilterCondition && (
              <DeleteButton onClick={() => onRemoveFilter(sectionIndex)} sx={{ mr: 1 }}>
                {pagesText.filters.removeFilterButton}
              </DeleteButton>
            )}
          </Stack>
        </Stack>
      ))}
    </Box>
  );
};

export default memo(ActiveFilterSection);
