import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Chip from '@mui/material/Chip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Popper, { PopperProps } from '@mui/material/Popper';
import { pagesText } from '../../constants/pagesText';
import { DEVICE_TAGS_QUERY_KEY } from '../../constants/query';
import { EXPANDED_ROWS_LIMIT } from '../../constants/table';
import { useEntityManager } from '../../context/EntityManagerContext';
import useDebounce from '../../hooks/auth/useDebounce';
import BackButton from '../../components/BackButton';
import { colors } from '../../theme/palette/darkPalette';
import SuccessButton from '../../components/SuccessButton';
import TagService from '../../services/tag.service';
import Iconify from '../../components/Iconify';
import useDialog from '../../hooks/useDialog';
import AlertRemoveTagDialog from './AlertRemoveTagDialog';
import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';

const StyledTextField = styled(TextField)({
  '& svg': {
    fill: '#fff',
  },
  '& label.Mui-focused': {
    color: colors.green,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.green,
    },
  },
});

const StyledChip = styled(Chip)({
  border: '0.5px solid #fff',
  backgroundColor: '#3f3f3f',
  '&:hover': {
    backgroundColor: '#3f3f3f',
  },
  '& .MuiChip-deleteIcon': {
    fill: '#fff',
    color: '#fff',

    '&:hover': {
      fill: '#fff',
      color: '#fff',
    },
  },
});

const DeviceTagModal = ({
  isOpen,
  onClose,
  onUpdateDeviceTag,
  onRemoveDeviceTag,
  onDeleteTag,
  tagName,
}) => {
  const { customerEnvId } = useEntityManager();
  const isInitialTagNameSet = useRef(false);
  const [searchValue, setSearchValue] = useState(tagName);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const isClickedOutside = useRef(false);
  const autocompleteRef = useRef(null);
  const [removeTagId, setRemoveTagId] = useState(null);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(null);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const { ref: lastElementRef, inView } = useInView();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTagId = searchParams.get('tagId');
  const {
    open: isRemoveTagDialogOpen,
    onOpen: onRemoveTagDialogOpen,
    onClose: onRemoveTagDialogClose,
  } = useDialog();

  const onSearchChange = useCallback(event => {
    if (!!selectedOptions?.length) return;

    setSearchValue(event?.target?.value.trim());
  }, [selectedOptions?.length]);

  const { data, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery(
    [DEVICE_TAGS_QUERY_KEY, debouncedSearchValue],
    async ({ pageParam = 1 }) => (
      await TagService.getTags({
        customerEnvId,
        page: pageParam,
        limit: EXPANDED_ROWS_LIMIT,
        search: debouncedSearchValue,
      })
    ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        const isMoreData = lastPage.tags.length >= EXPANDED_ROWS_LIMIT;

        if (isMoreData) {
          return currentPage + 1;
        }

        return undefined;
      },
      enabled: isOpen
    },
  );

  const fetchedData = useMemo(() => {
    const duplicateFilteredList = data?.pages?.reduce(
      (acc, page) => {
        acc.data = [...acc.data, ...page.tags];
        acc.count = Math.max(acc.count, page.count);
        if (page?.totalCount) acc.totalCount = page.totalCount;
        return acc;
      },
      {
        data: [],
        count: 0,
      },
    );

    const lastPage = data?.pages[data.pages?.length - 1];
    if (!lastPage)
      return {
        data: [],
        count: 0,
      };
    if (Object.keys(lastPage).length < 2) {
      return {
        ...lastPage,
        ...lastPage.tags,
      };
    }

    return {
      ...lastPage,
      ...duplicateFilteredList,
    };
  }, [data]);

  const tags = useMemo(() => fetchedData?.data || [], [fetchedData?.data]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (!isInitialTagNameSet.current && isOpen) {
      setSearchValue(tagName);
      setSelectedOptions(tagName ? [{ name: tagName }] : []);
      isInitialTagNameSet.current = true;
    }
  }, [tagName, isOpen]);

  const onKeyDown = useCallback(
    event => {
      if (!!selectedOptions?.length) {
        event.preventDefault();
        return;
      }

      if (event.key === 'Enter') {
        setIsAutocompleteOpen(false);
        if (searchValue) {
          setSelectedOptions([{ name: searchValue }]);
        }
      }
    },
    [searchValue, setIsAutocompleteOpen, selectedOptions?.length],
  );

  const onRemoveTagSearchParam = useCallback(() => {
    searchParams.delete('tagId');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const onDialogClose = useCallback(() => {
    onClose();
    setSearchValue('');
    setSelectedTag(null);
    onRemoveTagSearchParam();
    isInitialTagNameSet.current = false;
  }, [onClose, onRemoveTagSearchParam]);

  const onEditTag = useCallback(async () => {
    if (!tags.length) {
      onUpdateDeviceTag({ tagName: searchValue });
      setSearchValue('');
      setSelectedTag(null);
      isInitialTagNameSet.current = false;
      return;
    }

    const tagId = tags.find(tag => tag.name === tagName)?.id;
    if (!searchValue && activeTagId && !selectedTag?.id) {
      onRemoveDeviceTag();
      isInitialTagNameSet.current = false;
      return;
    }

    if (!tagName && !searchValue && !selectedTag?.name) {
      return;
    }
    if ((tagName === searchValue && !selectedTag?.name) || tagName === selectedTag?.name) {
      return;
    }

    onUpdateDeviceTag({
      ...(selectedTag?.name || searchValue ? { tagName: selectedTag?.name || searchValue } : {}),
      tagId: selectedTag?.id || tagId,
    });
    setSearchValue('');
    isInitialTagNameSet.current = false;
    setSelectedTag(null);
    onRemoveTagSearchParam();
  }, [tags, onUpdateDeviceTag, tagName, selectedTag, searchValue, activeTagId, onRemoveDeviceTag, onRemoveTagSearchParam]);

  const onTagRemoveClick = useCallback(
    (event, option) => {
      event.preventDefault();
      event.stopPropagation();
      setRemoveTagId(tags.find(tag => tag.name === option)?.id);
      onDialogClose();
      onRemoveTagDialogOpen();
    },
    [tags, setRemoveTagId, onRemoveTagDialogOpen, onDialogClose],
  );

  const onTagRemove = useCallback(
    () => {
      onDeleteTag({ tagId: removeTagId, tagSearch: debouncedSearchValue });
      onRemoveTagDialogClose();
    },
    [removeTagId, debouncedSearchValue, onRemoveTagDialogClose, onDeleteTag],
  );

  const onResetTag = useCallback(() => {
    setSearchValue('');
    setSelectedTag(null);
    setSelectedOptions([]);
    isInitialTagNameSet.current = false;
  }, []);

  useEffect(() => {
    if (selectedOptions?.length && !isAutocompleteOpen && !isClickedOutside.current) {
      setIsAutocompleteOpen(true);
    }
  }, [selectedOptions?.length, isAutocompleteOpen]);

  const PopperComponent = useCallback((props: PopperProps) =>
    <Popper
      {...props}
      id="autocomplete-popper"
      placement="bottom"
      sx={{
        '& .MuiAutocomplete-noOptions': {
          display: 'none'
        }
      }}
    />, []);

  const renderInput = useCallback(params => (
    <StyledTextField
      {...params}
      InputLabelProps={{
        sx: {
          color: '#fff',
        },
      }}
      InputProps={{
        ...params.InputProps,
        sx: {
          ...params.InputProps.sx,
          '& input': {
            color: 'transparent',
          },
        }
      }}
      onChange={onSearchChange}
      onKeyDown={onKeyDown}
      label="Tags"
    />
  ), [onSearchChange, onKeyDown]);

  useOutsideClickHandler(autocompleteRef, () => {
    setIsAutocompleteOpen(false);
    isClickedOutside.current = true;
  }, ['autocomplete-option-text', 'autocomplete-option', 'delete-icon']);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onDialogClose}
        PaperProps={{
          sx: {
            maxWidth: '400px',
            width: '100%',
          },
        }}>
        <DialogTitle>{pagesText.devices.editTagModal.title}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1}}>
            <Autocomplete
              ref={autocompleteRef}
              multiple
              PopperComponent={PopperComponent}
              disableClearable
              value={selectedOptions}
              loading={isFetching}
              open={isAutocompleteOpen}
              onOpen={() => setIsAutocompleteOpen(true)}
              onChange={(event, newTag) => {
                const selectedTag = tags.find(tag => tag.id === newTag[newTag.length - 1]?.id);
                setSelectedTag(selectedTag);
                setSelectedOptions([selectedTag]);
              }}
              isOptionEqualToValue={(option, val) => option?.name === val?.name}
              filterOptions={createFilterOptions({ trim: true })}
              options={fetchedData?.data || []}
              renderInput={renderInput}
              renderOption={({ key, ...optionProps }, option) => (
                <Stack
                  {...optionProps}
                  direction="row"
                  justifyContent="space-between"
                  key={key}
                  id="autocomplete-option"
                  ref={tags[tags.length - 1]?.name === option.name ? lastElementRef : null}>
                  <Box id="autocomplete-option-text" sx={{ flex: 1 }}>{option.name}</Box>
                  <IconButton onClick={event => onTagRemoveClick(event, option.name)}>
                    <Iconify icon="ic:round-delete" color="#fff" width={20} height={20} />
                  </IconButton>
                </Stack>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option: string, index: number) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  if (!option?.name) return null;

                  return (
                    <StyledChip
                      {...tagProps}
                      onClick={onResetTag}
                      label={option?.name}
                      key={key}
                      onDelete={onResetTag}
                      deleteIcon={<Iconify icon="ic:round-close" color="#fff" width={20} height={20} />}
                    />
                  );
                })
              }
              getOptionLabel={option => option?.name}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <BackButton onClick={onDialogClose}>{pagesText.devices.editTagModal.onCloseButton}</BackButton>
          <SuccessButton onClick={onEditTag}>Add</SuccessButton>
        </DialogActions>
      </Dialog>
      <AlertRemoveTagDialog
        open={isRemoveTagDialogOpen}
        onClose={onRemoveTagDialogClose}
        tagId={removeTagId}
        onSuccess={onTagRemove}
      />
    </>
  );
};

export default DeviceTagModal;
