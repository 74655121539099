import { forwardRef } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import TableNameCell from './TableNameCell';
import GenericTableCell from '../GenericTableCell';
import SecurityActions from '../DownloadActions';

/**
 *
 * @typedef TableExpandedRowProps
 * @property {Object} row
 */

/**
 * TableExpandedRow components
 *
 * @param {TableExpandedRowProps} props - props of component
 */

const TableExpandedRow = forwardRef(({ onClick, versions, showActions = true, columnsOrder }, ref) => {
  const renderCell = (column, version) => {
    const cellMaps = {
      environmentApplicationName: (
        <TableNameCell
          key={version.environmentApplicationName}
          name={version.environmentApplicationName}
          imgSrc={version.icon}
        />
      ),
    };

    return cellMaps[column] || <GenericTableCell key={version[column]} value={version[column]} />;
  };

  return (
    <>
      {versions.map((version, index) => (
        <TableRow
          onClick={() => onClick(version.id)}
          ref={index === versions.length - 1 ? ref : null}
          key={version.id}
          sx={{
            backgroundColor: '#393939',
            cursor: 'pointer',
          }}>
          {columnsOrder.map(column => renderCell(column, version))}
          <TableCell
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: 81,
            }}>
            <Stack direction="row">
              <SecurityActions policies={version.policies} versionId={version.id} />
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
});

export default TableExpandedRow;
