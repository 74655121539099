import { useEffect } from 'react';

export const useOutsideClickHandler = (
  ref,
  onOutsideClick,
  whitelist,
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      const tagName = event.target.tagName.toLowerCase();
      if (tagName === 'svg' || tagName === 'path') {
        return;
      }

      if (
        ref.current &&
        !(ref.current).contains(event.target) &&
      !whitelist?.includes(event.target?.id)
    ) {
        onOutsideClick();
      }
    }

    window?.addEventListener('mousedown', handleClickOutside);
    return () => {
      window?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick, ref, whitelist]);
};
