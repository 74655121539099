import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import EnvironmentForm from './EnvironmentForm';
import { modes } from './Environments';

const EnvironmentFormDialog = ({
  open,
  onClose,
  title,
  onExitAddMode,
  selectedEnvironment,
  mode,
  editEnvironmentId,
  onSuccess,
}) => {
  const onCancel = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 500,
          height: 630,
        },
      }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        <EnvironmentForm
          onExitAddMode={onExitAddMode}
          onClose={onCancel}
          isEditMode={mode === modes.edit}
          name={selectedEnvironment?.name}
          configManagerSupportId={selectedEnvironment?.configManagerSupportId}
          description={selectedEnvironment?.description}
          location={selectedEnvironment?.location}
          editEnvironmentId={editEnvironmentId}
          onSuccess={onSuccess}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EnvironmentFormDialog;
