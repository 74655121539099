import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DEVICES_DASHBOARD_QUERY_KEY, ENVIRONMENT_NAME_QUERY_KEY } from '../../constants/query';
import DeviceService from '../../services/device.service';
import { DeviceStatus, healthStatus, reportedDevicesMap } from './constants';
import { pagesText } from '../../constants/pagesText';
import ApplicationsService from '../../services/applications.service';
import { useEntityManager } from '../../context/EntityManagerContext';
import { PORTAL_ROUTE } from '../../constants/routes';
import {
  getDeviceWdacStatusFilerQuery,
  getDeviceHealthStatusFilterQuery,
} from '../../utils/devices.util';
import DeviceChart from './DeviceChart';
import Loader from '../../components/Loader';

const DevicesCharts = () => {
  const { customerEnvId } = useEntityManager();
  const { data: devicesData, isLoading } = useQuery(
    [DEVICES_DASHBOARD_QUERY_KEY, customerEnvId],
    () => DeviceService.getDeviceDashboard({ customerEnvId }),
  );
  const navigate = useNavigate();

  const enforcementStatusChartData = useMemo(() => {
    if (!devicesData?.enforcementStatus?.length) return [];
    const hasAnyDeviceCount = devicesData.enforcementStatus.some(
      enforcementStatus => !!enforcementStatus.deviceCount,
    );
    if (!hasAnyDeviceCount) return [];

    return devicesData.enforcementStatus.map(enforcementStatus => ({
      label: enforcementStatus.status,
      value: enforcementStatus.deviceCount,
      name: enforcementStatus.status,
      enforcementStatus,
    }));
  }, [devicesData?.enforcementStatus]);
  const deviceHealthChartData = useMemo(() => {
    if (!devicesData?.deviceHealth?.length) return [];
    const hasAnyDeviceCount = devicesData.deviceHealth.some(
      deviceHealth => !!deviceHealth.deviceCount,
    );
    if (!hasAnyDeviceCount) return [];

    return devicesData.deviceHealth.map(deviceHealth => ({
      label: reportedDevicesMap[deviceHealth.healthStatus],
      value: deviceHealth.deviceCount,
      name: reportedDevicesMap[deviceHealth.healthStatus],
    }));
  }, [devicesData?.deviceHealth]);
  const { data: environment } = useQuery([ENVIRONMENT_NAME_QUERY_KEY, customerEnvId], () =>
    ApplicationsService.getEnvironment(customerEnvId),
  );

  const onEnforcementStatusSlotClick = useCallback(
    data => {
      switch (data?.label) {
        case 1:
        case 'Audit':
          return navigate(
            `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
              getDeviceWdacStatusFilerQuery(DeviceStatus.audit),
            )}`,
          );
        case 2:
        case 'Enforced':
          return navigate(
            `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
              getDeviceWdacStatusFilerQuery(DeviceStatus.enforced),
            )}`,
          );
        default:
          navigate(
            `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
              getDeviceWdacStatusFilerQuery(DeviceStatus.unknown),
            )}`,
          );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onReportSlotClick = data => {
    if (data?.label.toLowerCase() === healthStatus.reported) {
      navigate(
        `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
          getDeviceHealthStatusFilterQuery(healthStatus.reported),
        )}`,
      );
      return;
    }
    if (data?.label === 'Not Reported') {
      navigate(
        `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
          getDeviceHealthStatusFilterQuery(healthStatus.notReported),
        )}`,
      );
    }
  };

  return (
    <Stack direction="row" gap={4}>
      <Stack gap={4} flex={1}>
        <Typography
          sx={{
            fontSize: 28,
            fontWeight: 'bold',
          }}>
          {environment?.name ? `${environment?.name} dashboard` : ''}
        </Typography>
        {isLoading ? (
          <Stack width="100%" justifyContent="center">
            <Loader />
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" gap={5} flexWrap="wrap">
            <DeviceChart
              title={pagesText.deviceDashboard.totalDeviceInEnvironment}
              data={enforcementStatusChartData}
              onItemClick={onEnforcementStatusSlotClick}
              isLoading={isLoading}
              chartStyles={{
                '& .MuiChartsLegend-root': {
                  transform: 'translateX(-20%)',
                },
              }}
            />
            <DeviceChart
              title={pagesText.deviceDashboard.devicesOnline}
              data={deviceHealthChartData}
              onItemClick={onReportSlotClick}
              isLoading={isLoading}
              chartStyles={{
                '& .MuiChartsLegend-root': {
                  transform: 'translateX(-10%)',
                },
              }}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default DevicesCharts;
