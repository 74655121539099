import api from './api';

const API_PREFIX = '/device';

const getDevices = ({ limit, page, customerEnvId, query }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/all${query}`, {
    params: {
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const updateStatus = ({ deviceId, status }) =>
  api.put(`${API_PREFIX}`, {
    status,
    deviceId,
  });

const getDeviceDashboard = ({ customerEnvId }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/dashboard`);

const getOsTypes = ({ customerEnvId, search, limit, page }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/osTypes`, {
    params: {
      search,
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const getOsSku = ({ customerEnvId, search, limit, page }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/osSku`, {
    params: {
      search,
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const getOsVersions = ({ customerEnvId, search, limit, page }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/osVersions`, {
    params: {
      search,
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const getLogCollectorVersions = ({ customerEnvId, search, limit, page }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/logCollectorVersions`, {
    params: {
      search,
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const DeviceService = {
  getDevices,
  updateStatus,
  getDeviceDashboard,
  getOsTypes,
  getOsSku,
  getOsVersions,
  getLogCollectorVersions,
};

export default DeviceService;
