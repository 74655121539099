const filterSectionNameMap = {
  status: 'FilterDevice',
  wdacDeviceStatus: 'FilterDevice',
  healthStatus: 'FilterDevice',
  osVersion: 'FilterDevice',
  osSku: 'FilterDevice',
  osType: 'FilterDevice',
  TagName: 'FilterDevice',
};

export const parseQueryToApiQuery = ({
  filters,
  page,
  limit,
  searchValue,
  omitSortFieldName = false,
  omitSortOrderName = false,
}) => {
  const queryParts = [];
  if (!!filters && Object.keys(filters).length > 0) {
    for (const [filterSectionName, filterSectionData] of Object.entries(filters)) {
      let filterSectionQueryData = {};
      let source = '';
      filterSectionData.forEach(section => {
        const [primarySection, secondarySection] = section.filter(sectionItem => sectionItem.value);
        if (primarySection && secondarySection) {
          if (filterSectionQueryData[primarySection.value]) {
            filterSectionQueryData[primarySection.value].push(secondarySection.value);
          } else {
            filterSectionQueryData[primarySection.value] = [secondarySection.value];
          }
        }
        if (primarySection && !secondarySection && primarySection?.name) {
          filterSectionQueryData[primarySection?.name] = [primarySection?.value];
        }
        if (section[0].order && !filterSectionQueryData.order) {
          filterSectionQueryData.order = section[0].order;
        }

        if (!source) {
          source = section.reduce((acc, sectionPart) => sectionPart?.source, '');
        }
      });

      Object.entries(filterSectionQueryData).forEach(([key, value]) => {
        const mappedFilerSectionName = filterSectionNameMap[filterSectionName] || filterSectionName;
        if (Array.isArray(value)) {
          queryParts.push(
            `${source ? `${source}.` : ''}${mappedFilerSectionName}.${key}=${value.join(',')}`,
          );
        } else if (key === 'order') {
          const filterSectionQueryDataItem = filterSectionData
            .flat()
            .find(filterSectionDataItem => filterSectionDataItem.order);
          queryParts.push(
            omitSortFieldName
              ? `sortBy=${filterSectionQueryDataItem?.sortingCellField || filterSectionName}`
              : `${filterSectionQueryDataItem?.sortFieldName || 'orderQuery'}.sortBy=${
                  filterSectionQueryDataItem?.sortingCellField || filterSectionName
                }`,
          );
          queryParts.push(
            omitSortOrderName
              ? `order=${filterSectionQueryDataItem?.order || value}`
              : `${filterSectionQueryDataItem?.sortFieldName || 'orderQuery'}.order=${value}`,
          );
        } else {
          queryParts.push(`${mappedFilerSectionName}.${key}=${value}`);
        }
      });
    }
  }
  // Todo: change this condition check
  if (
    searchValue !== 'undefined' &&
    searchValue !== '' &&
    searchValue !== null &&
    searchValue !== undefined
  ) {
    queryParts.push(`search=${searchValue}`);
  }

  if (!queryParts.length) return '';

  const queryString = queryParts.join('&');

  return `?${limit ? `Limit=${limit}` : ''}${page ? `&Page=${page}` : ''}&${queryString}`;
};
