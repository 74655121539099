import Stack from '@mui/material/Stack';
import { pagesText } from '../../constants/pagesText';
import SortingArrow from '../../components/SortingArrow';
import useTypographyMediaQuery from '../../hooks/useTypographyMediaQuery';
import TableHeadTypography from '../../components/TableHeadTypography';
export const sortingMethods = {
  asc: 'asc',
  desc: 'desc',
  initial: 'initial',
};

const CustomCell = ({ onCellSort, onDoubleClick, getCellSortMethod }) => {
  const { mediumScreen, largeScreen, smallScreen } = useTypographyMediaQuery();

  return (
    <Stack direction="row" gap={1} onDoubleClick={() => onDoubleClick('FilterDevice')}>
      <TableHeadTypography
        hasSorting={getCellSortMethod('FilterDevice', 'Name') !== sortingMethods.initial}
        mediumScreen={mediumScreen}
        largeScreen={largeScreen}
        smallScreen={smallScreen}>
        {pagesText.devices.headings.name}
      </TableHeadTypography>
      <SortingArrow
        hasSorting
        onClick={() => {
          return onCellSort({
            field: 'FilterDevice',
            sortingCellField: 'Name',
            sortMethod: getCellSortMethod('FilterDevice', 'Name'),
            sortFieldName: 'OrderDeviceQuery',
          });
        }}
        sortMethod={getCellSortMethod('FilterDevice', 'Name')}
      />
    </Stack>
  );
};

export const headings = [
  {
    title: pagesText.devices.headings.name,
    name: 'name',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
  },
  {
    title: pagesText.devices.headings.tagName,
    name: 'tagName',
    hasSorting: false,
  },
  {
    title: pagesText.devices.headings.lastContactDate,
    name: 'lastContactDate',
    hasSorting: false,
  },
  {
    title: pagesText.devices.headings.wdacStatus,
    name: 'wdacDeviceStatus',
    hasSorting: false,
  },
  {
    title: pagesText.devices.headings.osVersion,
    name: 'osVersion',
    hasSorting: false,
  },
  {
    title: pagesText.devices.headings.osSku,
    name: 'osSku',
    hasSorting: false,
  },
  {
    title: pagesText.devices.headings.osType,
    name: 'osType',
    hasSorting: false,
  },
  {
    title: pagesText.devices.headings.logCollectorVersion,
    name: 'logCollectorVersion',
    hasSorting: false,
  },
  {
    title: pagesText.devices.headings.status,
    name: 'status',
    hasSorting: false,
  },
];

export const reversedSortMap = {
  [sortingMethods.asc]: sortingMethods.desc,
  [sortingMethods.desc]: sortingMethods.asc,
};

export const deviceStatus = {
  approved: 'approved',
  revoked: 'revoked',
  unknown: 'unknown',
};

export const deviceStatuses = [
  {
    label: 'Approved',
    value: deviceStatus.approved,
  },
  {
    label: 'Revoked',
    value: deviceStatus.revoked,
  },
  {
    label: 'Unknown',
    value: deviceStatus.unknown,
  },
];

export const columnsOrder = [
  'name',
  'tagName',
  'lastContactDate',
  'wdacDeviceStatus',
  'osVersion',
  'osSku',
  'osType',
  'logCollectorVersion',
  'status',
];

export const VISIBLE_DEVICES_COUNT = 5;
