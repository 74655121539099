import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useQueryParams = ({ defaultRowsPerPage } = { defaultRowsPerPage: 10 }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryObject = useMemo(() => {
    const entries = Array.from(searchParams.entries());
    const { page, limit, search, filters } = entries.reduce((acc, item) => {
      const [key, value] = item;
      if (key === 'filters') {
        acc.filters = JSON.parse(value);
        return acc;
      }
      acc[key] = value;
      return acc;
    }, {});

    const parsedNumber = Number(page || 1);
    const rowsPerPage = Number(limit || defaultRowsPerPage);
    return {
      ...(page ? { page: parsedNumber } : {}),
      ...(rowsPerPage ? { limit: rowsPerPage } : {}),
      ...(search ? { searchValue: search } : {}),
      filters,
    };
  }, [defaultRowsPerPage, searchParams]);

  const onApplySearchParams = useCallback(
    ({ filters, page, limit, searchValue, ...rest }) => {
      setSearchParams({
        ...(filters ? { filters: JSON.stringify(filters) } : {}),
        ...(searchValue ? { search: searchValue } : {}),
        page,
        limit,
        ...rest,
      });
    },
    [setSearchParams],
  );

  const onResetSearchParams = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const changeFieldInURL = useCallback(
    fields => {
      fields.forEach(({ key, value }) => {
        if (key && value === '') {
          searchParams.delete(key);
          setSearchParams(searchParams);
          return;
        }

        searchParams.set(key, value);
        setSearchParams(searchParams);
      });
    },
    [searchParams, setSearchParams],
  );

  return { queryObject, onApplySearchParams, onResetSearchParams, changeFieldInURL };
};

export default useQueryParams;
