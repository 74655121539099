import React, { memo } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { COLORS } from './constants';
import LegendRow from './LegendRow';
import { numDigitsCount } from '../../utils/helpers';

const renderLabel = ({
  value,
  outerRadius,
  cx,
  cy,
  midAngle,
  fill,
  index,
  innerRadius,
  totalChartValue,
}) => {
  if (value === 0) return null;
  const percentageOfValue = (value / totalChartValue) * 100;

  if (percentageOfValue > 10) {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const valueDigitsCount = numDigitsCount(value);
    const valueXCoordinateCoefficient = -16 / valueDigitsCount;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) + valueXCoordinateCoefficient;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} fill="#fff">
        {value}
      </text>
    );
  }

  const RADIAN = Math.PI / 180;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + outerRadius * cos;
  const sy = cy + outerRadius * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my + 10 * (index % 2 === 0 ? -1 : 1);
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#fff">
        {value}
      </text>
    </>
  );
};
const DeviceChart = ({ title, data, onItemClick }) => (
  <Stack
    gap={1}
    flex={1}
    justifyContent="center"
    sx={{
      backgroundColor: '#454545',
      borderRadius: 4,
      padding: 2,
      maxHeight: 300,
      height: '100%',
    }}>
    <Typography
      sx={theme => ({
        fontSize: theme.typography.pxToRem(28),
        fontWeight: 'bold',
        textAlign: 'center',
      })}>
      {title}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        maxWidth: 600,
        maxHeight: 200,
        height: '100%',
      }}>
      <Stack direction="row" alignItems="center">
        {!data.length ? (
          <Typography
            sx={theme => ({
              fontSize: theme.typography.pxToRem(18),
              fontWeight: '300',
              textAlign: 'center',
            })}>
            No data
          </Typography>
        ) : (
          <PieChart width={350} height={200}>
            <Pie
              strokeWidth={0}
              paddingAngle={0}
              dataKey="value"
              isAnimationActive={false}
              labelLine={false}
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label={props =>
                renderLabel({
                  ...props,
                  totalChartValue: data.reduce((acc, curr) => acc + curr.value, 0),
                })
              }
              onClick={data => {
                onItemClick(data);
              }}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        )}
        <Stack justifyContent="center">
          {data.map(entry => {
            if (entry.value === 0) return null;

            return (
              <LegendRow
                key={entry.name}
                color={COLORS[data.indexOf(entry) % COLORS.length]}
                title={entry.label}
                onClick={() => onItemClick(entry)}
              />
            );
          })}
        </Stack>
      </Stack>
    </Box>
  </Stack>
);

export default memo(DeviceChart);
