import { memo } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Iconify from '../Iconify';
import { colors } from '../../theme/palette/darkPalette';
import { sortingMethods } from './Table';
import TableHeadTypography from '../TableHeadTypography';
import useTypographyMediaQuery from '../../hooks/useTypographyMediaQuery';
import SortingArrow from '../SortingArrow';

const StyledTableHead = styled(TableHead, {
  shouldForwardProp: prop => prop !== 'hasCorner',
})(({ hasCorner }) => ({
  backgroundColor: '#3f3f3f',
  borderRadius: 0,
  ...(hasCorner
    ? {
        '.MuiTableRow-root th:first-child': {
          borderTopLeftRadius: '10px',
        },
        '.MuiTableRow-root th:last-child': {
          borderTopRightRadius: '10px',
        },
      }
    : {}),
}));

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'mediumScreen' && prop !== 'largeScreen',
})(({ mediumScreen, largeScreen }) => ({
  padding: '10px 16px',
  ...(mediumScreen || largeScreen
    ? {
        padding: '10px',
      }
    : {}),
}));

const TableHeading = ({
  onOpenFilters,
  hasFilters,
  headings,
  onSortTable,
  onResetSortField,
  getListItemSortMethod,
  hasFilter = true,
  hasHeaderCorner = false,
  onCellSort,
  getCellSortMethod,
  hasActions = false,
  children,
  listCellStyles,
  listCellColSpan,
}) => {
  const { mediumScreen, largeScreen, smallScreen } = useTypographyMediaQuery();

  return (
    <StyledTableHead hasCorner={hasHeaderCorner}>
      {children}
      <TableRow>
        {headings.map(({ Component, ...headingProps }, index) => {
          const sortMethod = !getListItemSortMethod
            ? sortingMethods.initial
            : getListItemSortMethod(headingProps?.name);

          return (
            <>
              <StyledTableCell
                key={`${headingProps.title}-${index}`}
                mediumScreen={mediumScreen}
                largeScreen={largeScreen}
                colSpan={listCellColSpan}
                sx={{
                  top: '110px',
                  ...listCellStyles,
                }}>
                <Stack gap={1} direction="row" alignItems="center">
                  {!!Component ? (
                    <Component
                      title={headingProps.title}
                      name={headingProps.name}
                      onDoubleClick={onResetSortField}
                      onCellSort={onCellSort}
                      getCellSortMethod={getCellSortMethod}
                    />
                  ) : (
                    <TableHeadTypography
                      onDoubleClick={() => onResetSortField(headingProps.name)}
                      mediumScreen={mediumScreen}
                      largeScreen={largeScreen}
                      smallScreen={smallScreen}
                      hasSorting={sortMethod !== sortingMethods.initial}>
                      {headingProps.title}
                    </TableHeadTypography>
                  )}
                  {headingProps?.hasSorting && (
                    <SortingArrow
                      onClick={() =>
                        onSortTable(
                          sortMethod === sortingMethods.initial ? sortingMethods.asc : sortMethod,
                          headingProps.name,
                        )
                      }
                      sortMethod={sortMethod}
                      hasSorting={headingProps?.hasSorting}
                    />
                  )}
                </Stack>
              </StyledTableCell>
            </>
          );
        })}
        {hasFilter && (
          <StyledTableCell
            sx={{
              top: '80px',
            }}
            mediumScreen={mediumScreen}
            largeScreen={largeScreen}>
            <Stack direction="row" justifyContent="center">
              <Iconify
                onClick={onOpenFilters}
                icon="octicon:filter-16"
                color={hasFilters ? colors.green : '#fff'}
                width={16}
                height={16}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </Stack>
          </StyledTableCell>
        )}
        {hasActions && (
          <StyledTableCell mediumScreen={mediumScreen} largeScreen={largeScreen}>
            <Stack direction="row" justifyContent="center">
              <Box width={16} height={16} />
            </Stack>
          </StyledTableCell>
        )}
      </TableRow>
    </StyledTableHead>
  );
};

export default memo(TableHeading);
