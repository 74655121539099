import { defaultDeviceFilters } from '../components/Filters/constants';

export const getDeviceWdacStatusFilerQuery = status => ({
  ...defaultDeviceFilters,
  wdacDeviceStatus: [
    [
      {
        ...defaultDeviceFilters.wdacDeviceStatus[0][0],
        value: status,
      },
    ],
  ],
});

export const getDeviceHealthStatusFilterQuery = status => ({
  ...defaultDeviceFilters,
  healthStatus: [
    [
      {
        ...defaultDeviceFilters.healthStatus[0][0],
        value: status,
      },
    ],
  ],
});

export const getDeviceTagIdFilterQuery = tagId => ({
  ...defaultDeviceFilters,
  tagId: [
    [
      {
        ...defaultDeviceFilters.tagId[0][0],
        value: tagId,
      },
    ],
  ],
});
